import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { concatClassNames as cn } from "@system42/core";

import { featureOverviewFeatures } from "../../helpers";
import { HighlightedText, Faqs } from "../../system42";

import Layout from "../../layouts/layout";
import { GetStartedBox } from "../../components/GetStartedBox";
import { HeaderOverlap } from "../../components/HeaderOverlap";
import { FeatureOverview } from "../../components/FeatureOverview";
import { ThreeColumnsIcons } from "../../components/ThreeColumnsIcons";
import { QuestionAnswerType } from "../../components/QuestionAnswerType";

import imageTaskIllustration from "../../images/taskTypes/taskIllustration.jpg";
import imageRatingScaleIllustration from "../../images/taskTypes/ratingScaleIllustration.jpg";
import imageSingleChoiceIllustration from "../../images/taskTypes/singleChoiceIllustration.jpg";
import imageMultipleChoiceIllustration from "../../images/taskTypes/multipleChoiceIllustration.jpg";
import imageWrittenResponseIllustration from "../../images/taskTypes/writtenResponseIllustration.jpg";

import imageTaskIcon from "../../images/taskTypes/taskIcon.svg";
import imageRatingScaleIcon from "../../images/taskTypes/ratingScaleIcon.svg";
import imageSingleChoiceIcon from "../../images/taskTypes/singleChoiceIcon.svg";
import imageMultipleChoiceIcon from "../../images/taskTypes/multipleChoiceIcon.svg";
import imageWrittenResponseIcon from "../../images/taskTypes/writtenReponseIcon.svg";

import imageIconAutomatedReports from "../../images/featureIcons/feature-automated-reports.svg";
import imageIconComprehensiveOverview from "../../images/lineIcons/comprehensive-overview.svg";
import imageIconAutomaticallyCreatedReports from "../../images/lineIcons/automatically-created-reports.svg";
import imageIconAlwaysTeamReady from "../../images/lineIcons/always-team-ready.svg";

import * as helperStyles from "../helpers.module.css";

export default function AutomatedReports() {
  return (
    <Layout
      activeMenuItem={"features/automated-reports"}
      title={"Automated Reports"}
      metaDescription={
        "Explore comprehensive and automatically generated user test reports designed to provide detailed insights into your test results, enhancing your analysis and understanding."
      }
    >
      <article>
        <HeaderOverlap
          isDisplayCta
          featureIcon={imageIconAutomatedReports}
          featureIconAlt={"Automated Reports"}
          overline={"Automated Reports"}
          title={
            <>
              <span className={helperStyles.h1Serif}>One big summary – </span>
              &nbsp;automatically created
            </>
          }
          titleWidth={"12em"}
          buttonSubtext={"No credit card required"}
          staticImage={(className) => (
            <StaticImage
              src={"../../images/summarizedReport.jpg"}
              className={className}
              alt={"Report of testers' experience"}
            />
          )}
        />
        <div className={helperStyles.container}>
          <ThreeColumnsIcons
            className={helperStyles.spacingLg}
            title={"In-depth analysis for your user tests"}
            titleWidth={"17em"}
            //
            col1Heading={"A comprehensive overview"}
            col1Text={
              "Reports are a detailed overview of all your test results. They include task success, time on task, various answer types, like rating scales, as well as all your notes, clips and AI insights."
            }
            col1Image={imageIconComprehensiveOverview}
            col1Alt={"Line drawing of a paper report"}
            //
            col2Heading={"Reports are created automatically"}
            col2Text={
              "Reports present a summary of all your tasks and questions and testers’ responses. It’s a visual tool to identify areas of interest and delve  into test results more deeply."
            }
            col2Image={imageIconAutomaticallyCreatedReports}
            col2Alt={"Line drawing of a hand showing an OK sign"}
            //
            col3Image={imageIconAlwaysTeamReady}
            col3Heading={"Always ready for your team"}
            col3Text={
              "Enrich your reports by adding your own notes and clips for highlights. Share reports with your team and let everyone explore them  independently."
            }
            col3Alt={"Line drawing of a group of people"}
          />

          {/* XXX: This could potentially become a generic component. Jerry? */}
          <QuestionAnswerType.Group
            className={helperStyles.spacingLg}
            title="Various question and answer types:"
          >
            <QuestionAnswerType
              title="Task"
              icon={imageTaskIcon}
              iconAlt="Paper with checkmark"
              exampleText="Add 5 products to your cart and continue to checkout."
              image={imageTaskIllustration}
              imageAlt="Pie chart describing difficulty"
            />
            <QuestionAnswerType
              title="Rating scale"
              icon={imageRatingScaleIcon}
              iconAlt="Speedometer"
              exampleText="How would you rate your overall experience?"
              image={imageRatingScaleIllustration}
              imageAlt="Bar chart describing user experience"
            />
            <QuestionAnswerType
              title="Single choice"
              icon={imageSingleChoiceIcon}
              iconAlt="Bullet points with first item selected"
              exampleText="How often do you use websites like this?"
              image={imageSingleChoiceIllustration}
              imageAlt="Horizontal bar chart describing user frequency of use"
            />
            <QuestionAnswerType
              title="Multiple choice"
              icon={imageMultipleChoiceIcon}
              iconAlt="Checklist"
              exampleText="Which of the following features seem useful to you?"
              image={imageMultipleChoiceIllustration}
              imageAlt="Horizontal bar chart describing user feature preferences"
            />
            <QuestionAnswerType
              title="Written response"
              icon={imageWrittenResponseIcon}
              iconAlt="Capital letter T"
              exampleText="Is there anything you would like to improve on this website?"
              image={imageWrittenResponseIllustration}
              imageAlt="Word cloud describing user feedback"
            />
          </QuestionAnswerType.Group>

          <Faqs
            className={cn(helperStyles.spacingLg)}
            questions={[
              {
                question:
                  "Are automated test reports included with my subscription?",
                answer:
                  "Good news, all subscriptions include automated test reports. You’ll have  access to automated test reports as long as your subscription is  active. Please note, automated test reports are not available with our  Pay-As-You-Go plan.",
              },
            ]}
          />

          <GetStartedBox
            title={
              <>
                You want{" "}
                <HighlightedText cursor>automated reports</HighlightedText> for
                your user tests?
              </>
            }
            titleWidth={"15em"}
            className={helperStyles.spacingLg}
          />
        </div>

        <div
          className={cn(
            helperStyles.container,
            helperStyles.container_featureOverview
          )}
        >
          <FeatureOverview
            className={helperStyles.spacingLg}
            title={
              "Userbrain features for easy, unmoderated remote user testing"
            }
            titleWidth={"16em"}
            features={featureOverviewFeatures}
          />
        </div>
      </article>
    </Layout>
  );
}
