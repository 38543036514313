import React from "react";
import { concatClassNames as cn } from "@system42/core";
import { Headline2Sans } from "../../system42";

import * as styles from "./styles.module.css";

export function QuestionAnswerType({
  icon,
  iconAlt,
  title,
  exampleText,
  image,
  imageAlt,
  className,
}) {
  return (
    <div className={cn(styles.questionAnswerType, className)}>
      <div className={styles.titleAndIcon}>
        <img src={icon} alt={iconAlt} />
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.exampleText}>{exampleText}</div>
      <img src={image} alt={imageAlt} />
    </div>
  );
}

QuestionAnswerType.Group = function Group({
  className,
  title,
  children,
  titleWidth,
}) {
  return (
    <div className={className}>
      <Headline2Sans
        className={styles.groupTitle}
        style={{ maxWidth: titleWidth }}
      >
        {title}
      </Headline2Sans>
      <div className={styles.group}>{children}</div>
    </div>
  );
};
